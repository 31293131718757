<!-- =========================================================================================
  File Name: EditHoverpack.vue
  Description: Form to edit hobject, placement and anchor in the same form
========================================================================================== -->

<template>
  <!-- <p>Implement Form validation with form wizard using popular <strong> <router-link to="/forms/form-validation">VeeValidate</router-link></strong></p> -->
  <div>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-type="flex" vs-justify="center">
        <div class="vx-col w-full mb-base">
          <vx-card
            v-if="hobject && hobject.model_identifier"
            :title="getCardTitleFromModelIdentifier(hobject.model_identifier)"
          >
            <!-- {{ hobject }} -->
            <!-- {{ this.hobject.model_identifier }} -->
            <!-- {{ hobject.abilities }} -->
            <!-- {{ this.$refs.form }} -->
            <!-- formTab1 -->
            <vs-col vs-type="flex" class="mb-6" vs-justify="center">
              <div class="vx-col w-1/2 mr-4">
                <image-upload
                  class="w-full"
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-if="hobject.model_identifier == 'hoverlay.core.Image.1.0'"
                ></image-upload>
                <cta
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-if="hobject.model_identifier == 'hoverlay.core.Button.1.0'"
                ></cta>
                <video-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:isInEditMode="true"
                  v-if="hobject.model_identifier == 'hoverlay.core.Video.1.0'"
                ></video-upload>
                <chroma-video-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:isInEditMode="true"
                  v-if="hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0'"
                ></chroma-video-upload>
                <video-image-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-if="hobject.model_identifier == 'hoverlay.core.Portal.1.0'"
                ></video-image-upload>
                <audio-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-if="hobject.model_identifier == 'hoverlay.core.Audio.1.0'"
                ></audio-upload>
                <glb-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:initialModel="model"
                  v-if="hobject.model_identifier == 'hoverlay.core.3dModel.1.0'"
                ></glb-upload>
                <uab-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:initialModel="model"
                  v-if="hobject.model_identifier == 'hoverlay.core.UnityAssetBundle.1.0'"
                ></uab-upload>
                <form-audio
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-if="hobject.model_identifier == 'hoverlay.core.Audio.1.0'"
                ></form-audio>
                <form-text
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:initialModel="model"
                  v-if="hobject.model_identifier == 'hoverlay.core.TextCard.1.0'"
                ></form-text>
              </div>
              <div class="vx-col w-1/2 ml-4">
                <form-name-description
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:initialModel="model"
                ></form-name-description>
                <form-glb
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:initialModel="model"
                  v-if="hobject.model_identifier == 'hoverlay.core.3dModel.1.0'"
                ></form-glb>
                <div v-if="hobject.model_identifier == 'hoverlay.core.Audio.1.0'">
                  <form-audio-volume
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:initialModel="model"
                  ></form-audio-volume>
                  <form-change-audio-mode
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:initialModel="model"
                  ></form-change-audio-mode>
                  <form-audio-controls
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:initialModel="model"
                  ></form-audio-controls>
                </div>
                <div
                  v-if="
                    hobject.model_identifier == 'hoverlay.core.Image.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Video.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Button.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.TextCard.1.0'
                  "
                >
                  <form-physical-height
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:abilityName="'skin'"
                    v-bind:initialModel="model"
                  ></form-physical-height>

                  <div
                    v-if="
                      hobject.model_identifier == 'hoverlay.core.Video.1.0' ||
                        hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0'
                    "
                  >
                    <form-video-volume
                      v-multi-ref:form
                      v-bind:hobject="hobject"
                      v-bind:initialModel="model"
                    ></form-video-volume>
                    <form-video-controls
                      v-multi-ref:form
                      v-bind:hobject="hobject"
                      v-bind:initialModel="model"
                    ></form-video-controls>
                    <div v-if="hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0'">
                      <vs-divider />
                      <!-- <label for="" class="vs-input--label">Volume</label> -->
                      <form-chroma-settings
                        v-if="!isChromaKeyTweakerVisible"
                        v-multi-ref:form
                        v-bind:hobject="hobject"
                        v-bind:initialModel="model"
                      ></form-chroma-settings>
                      <form-change-chroma-key-algo
                        v-multi-ref:form
                        v-bind:hobject="hobject"
                        v-bind:initialModel="model"
                      ></form-change-chroma-key-algo>
                    </div>
                    <vs-divider />
                  </div>
                  <form-orientation
                    v-if="hobject.model_identifier != 'hoverlay.core.Portal.1.0'"
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:initialModel="model"
                  ></form-orientation>
                </div>

                <div
                  v-if="
                    hobject.model_identifier == 'hoverlay.core.Image.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Video.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Portal.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.TextCard.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.UnityAssetBundle.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.3dModel.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Button.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Audio.1.0'
                  "
                >
                  <form-interaction
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:initialModel="model"
                  ></form-interaction>
                  <form-abilities v-if="isHobjectAbilitiesEnable" :hobject="hobject"></form-abilities>
                </div>
              </div>
            </vs-col>

            <div class="vx-row">
              <div class="vx-col w-full" v-if="hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0'">
                <chroma-key-tweaker
                  v-if="isChromaKeyTweakerVisible"
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:videoUrl="greenScreenVideoUrl"
                ></chroma-key-tweaker>
              </div>
            </div>
            <!-- formTab2 -->
            <vs-divider />
            <br />
            <div class="vx-row">
              <div class="vx-col w-9/12"></div>
              <div class="vx-col w-3/12">
                <div class="vx-row">
                  <div class="vx-col w-1/2">
                    <vs-button type="border" @click="$router.go(-1)" class="w-full mr-3 mb-2">Cancel</vs-button>
                  </div>
                  <div class="vx-col w-1/2">
                    <vs-button @click="submitData()" class="w-full mr-3 mb-2">Save</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import * as Utils from '@/assets/js/utils/utils.js'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import FormAbilities from '@/views/hoverlay/components/objects-form/FormAbilities.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ImageUpload from '@/views/hoverlay/components/objects-form-upload/ImageUpload.vue'
import AudioUpload from '@/views/hoverlay/components/objects-form-upload/AudioUpload.vue'
import FormAudio from '@/views/hoverlay/components/objects-form/FormAudio.vue'
import VideoUpload from '@/views/hoverlay/components/objects-form-upload/VideoUpload.vue'
import ChromaVideoUpload from '@/views/hoverlay/components/objects-form-upload/ChromaVideoUpload.vue'
import FormGlb from '@/views/hoverlay/components/objects-form/FormGlb.vue'
import VideoImageUpload from '@/views/hoverlay/components/objects-form-upload/VideoImageUpload.vue'
import FormText from '@/views/hoverlay/components/objects-form/FormText.vue'
import GlbUpload from '@/views/hoverlay/components/objects-form-upload/GlbUpload.vue'
import UabUpload from '@/views/hoverlay/components/objects-form-upload/UabUpload.vue'
import FormNameDescription from '@/views/hoverlay/components/objects-form/FormNameDescription.vue'
import FormVideoVolume from '@/views/hoverlay/components/objects-form/FormVideoVolume.vue'
import FormChromaSettings from '@/views/hoverlay/components/objects-form/FormChromaSettings.vue'
import FormChangeChromaKeyAlgo from '@/views/hoverlay/components/objects-form/FormChangeChromaKeyAlgo.vue'
import FormAudioVolume from '@/views/hoverlay/components/objects-form/FormAudioVolume.vue'
import FormChangeAudioMode from '@/views/hoverlay/components/objects-form/FormChangeAudioMode.vue'
import FormAudioControls from '@/views/hoverlay/components/objects-form/FormAudioControls.vue'
import FormPhysicalHeight from '@/views/hoverlay/components/objects-form/FormPhysicalHeight.vue'
import FormOrientation from '@/views/hoverlay/components/objects-form/FormOrientation.vue'
import Cta from '@/views/hoverlay/components/objects-form/CTA.vue'
import FormInteraction from '@/views/hoverlay/components/objects-form/FormInteraction.vue'
import ChromaKeyTweaker from '@/views/hoverlay/components/objects-form/ChromaKeyTweaker/Scene.vue'
import FormVideoControls from  '@/views/hoverlay/components/objects-form/FormVideoControls.vue'

export default {
  data() {
    return {
      EditHobjectOnly: false,
      placement: null,
      hobject: null,
      anchor: null,
      model: null,
      userRole: HoverlayUtils.getUserRole(),
      isHobjectAbilitiesEnable: HoverlayUtils.isHobjectAbilitiesEnable(),
      isChromaKeyTweakerVisible: false,
      greenScreenVideoUrl: null,
    }
  },
  watch: {
    hobject: {
      handler() {
        this.isChromaKeyTweakerVisible = this.ComputeIsChromaKeyTweakerVisible()
      },
      deep: true,
    },
    greenScreenVideoUrl: {
      handler(updatedValue) {
        this.isChromaKeyTweakerVisible = this.ComputeIsChromaKeyTweakerVisible()
      },
      deep: true,
    },
  },
  computed: {
    placements() {
      return this.$store.state.hoverlay.placements
    },
    hobjects() {
      return this.$store.state.hoverlay.hobjects
    },
    models() {
      return this.$store.state.hoverlay.models
    },
  },
  async created() {
    // fetch anchors from the api
    // this.$store.dispatch('hoverlay/fetchAnchors', this.modifiedPlacements)
    // fetch hobject from the api
    await this.$store.dispatch('hoverlay/getHobject', this.$route.params.hobject_pid)
    // usefull to toggle on/off the panel to adjust greenscreen settings
    this.$eventBus.$on('chromaKeyVideoUrlUpdated', this.onChromaKeyVideoUrlUpdated)
    this.init()
  },
  mounted() {},
  methods: {
    getCardTitleFromModelIdentifier(model_identifier) {
      switch (model_identifier) {
        case 'hoverlay.core.Audio.1.0':
          return this.$t('HoverpackAudioTitle')
        case 'hoverlay.core.Video.1.0':
          return this.$t('HoverpackVideoTitle')
        case 'hoverlay.core.ChromaVideo.1.0':
          return this.$t('HoverpackHologramTitle')
        case 'hoverlay.core.Image.1.0':
          return this.$t('HoverpackImageTitle')
        case 'hoverlay.core.Button.1.0':
          return this.$t('HoverpackButtonTitle')
        case 'hoverlay.core.3dModel.1.0':
          return this.$t('HoverpackModelTitle')
        case 'hoverlay.core.Portal.1.0':
          return this.$t('HoverpackPortalTitle')
        case 'hoverlay.core.TextCard.1.0':
          return this.$t('HoverpackTextTitle')
        case 'hoverlay.core.UnityAssetBundle.1.0':
          return this.$t('HoverpackUnityAssetBundleTitle')
        default:
          return ''
      }
    },

    // find corresponding anchor, space and hobject in local database
    init() {
      if (this.$route.params.placementPid) {
        this.EditHobjectOnly = false
        this.placement = JSON.parse(
          JSON.stringify(
            this.$store.state.hoverlay.placements.filter(
              placement => placement.pid == this.$route.params.placementPid
            )[0]
          )
        )
        this.hobject = JSON.parse(
          JSON.stringify(
            this.$store.state.hoverlay.hobjects.filter(hobject => hobject.pid == this.placement.hobject_pid)[0]
          )
        )
        this.model = JSON.parse(
          JSON.stringify(this.$store.state.hoverlay.models.filter(model => model.pid == this.hobject.modelPid)[0])
        )
        this.anchor = JSON.parse(
          JSON.stringify(
            this.$store.state.hoverlay.anchors.filter(anchor => anchor.pid == this.placement.anchor_pid)[0]
          )
        )
      }

      if (this.$route.params.hobject_pid) {
        this.EditHobjectOnly = true
        this.hobject = JSON.parse(
          JSON.stringify(
            this.$store.state.hoverlay.hobjects.filter(hobject => hobject.pid == this.$route.params.hobject_pid)[0]
          )
        )
        // this.model = JSON.parse(JSON.stringify(this.$store.state.hoverlay.models.filter(model => model.pid == this.hobject.modelPid)[0]))
      }
    },
    async updateHobject(hobject) {
      this.$vs.loading({ text: ' ' }) // Add blank text so we can modified it later (progress pourcentage)
      if (hobject.data) {
        if (hobject.model_identifier == 'hoverlay.core.3dModel.1.0' && hobject.compress_glb == true) {
          try {
            var response = await this.$store.dispatch('hoverlay/compressGlb', hobject.data)
            hobject.data = Utils.blobToFile(response, hobject.data.name)
          } catch (e) {
            console.error(e)
          }
        }
        if (hobject.data) {
          const signed_id = await HoverlayUtils.directUpload(hobject.data, this.directUploadWillStoreFileWithXHR)
          hobject['asset'] = signed_id
          // hobject['compress_video'] = true
          delete hobject.data
        }
      }

      // let hobjectFormData = new FormData()
      // Object.keys(hobject).forEach(function(key) {
      //   if (hobject[key]) hobjectFormData.append(key, hobject[key])
      //   console.log(key, hobject[key])
      // })
      if (!(hobject.image instanceof Blob)) delete hobject.image
      try {
        this.hobject = await this.$store.dispatch('hoverlay/updateHobject', hobject)
        if (!this.EditHobjectOnly)
          this.$router.push({
            name: 'myspaces',
          })
        this.$vs.notify({
          color: 'success',
          title: 'Success',
          text: 'The object has been updated! ',
        })
      } catch (error) {
        console.log(error)
        console.log(error.name)
        if (error.name == 'TypeError') {
          this.$vs.notify({
            title: 'Connection is too slow',
            text: 'Try again from a different connection.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            fixed: true,
            click: () => {
              // Secondary function
            },
          })
        } else
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
      }
      this.$vs.loading.close()
    },

    ResetData() {
      this.init()
    },
    async submitData() {
      if (this.EditHobjectOnly == true) {
        var isFormsValid = await this.validateAll()
        if (isFormsValid) {
          await this.updateHobject(this.hobject)
          this.$router.go(-1)
        }
      }
    },
    async validateAll() {
      var success = true
      var forms = this.$refs.form
      for (let index = 0; index < forms.length; index++) {
        const form = forms[index]
        var formValid = true
        if (form.validate != null) formValid = await form.validate()
        success = success && formValid
      }
      return success
    },
    ComputeIsChromaKeyTweakerVisible() {
      try {
        var abilities = JSON.parse(this.hobject.abilities)
        if (abilities.skin && abilities.skin.chroma_key_algorithm == 'advanced' && this.greenScreenVideoUrl) return true
        else return false
      } catch (e) {}
    },
    onChromaKeyVideoUrlUpdated(greenScreenVideoUrl) {
      this.greenScreenVideoUrl = greenScreenVideoUrl
    },
  },
  components: {
    ImageUpload,
    FormAudio,
    AudioUpload,
    VideoUpload,
    ChromaVideoUpload,
    ChromaKeyTweaker,
    FormGlb,
    FormText,
    GlbUpload,
    UabUpload,
    FormAbilities,
    FormNameDescription,
    FormVideoVolume,
    FormPhysicalHeight,
    FormOrientation,
    FormInteraction,
    FormAudioVolume,
    FormChangeAudioMode,
    FormAudioControls,
    FormChromaSettings,
    FormChangeChromaKeyAlgo,
    FormVideoControls,
    VideoImageUpload,
    Cta,
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
